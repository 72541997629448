import React, { useState, useEffect, useRef } from 'react';
import DynamicTable from '../DynamicTable';
import { getImgLink, uploadImgforDocument } from '../../services/highlightsApi';

const DocumentHighlightsModal = ({ isOpen, onClose, onSave, highlight,  tempDocument, initialText } ) => {
  const [newText, setNewText] = useState(initialText);
  const [newImage, setNewImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState('')
  const [highlightType, setHighlightType] = useState('text');
  const tableDivRef = useRef(null);
  const inputRef = useRef();
   

 const handleImageUpload = (event) => {
    const file = event.target.files[0];
   if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setNewImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteRow = (rowIndex) => {
    const table = tableDivRef.current.querySelector('table');
    table.deleteRow(rowIndex);
  };
  
  const handleDeleteColumn = (columnIndex) => {
    const table = tableDivRef.current.querySelector('table');
    for (let i = 0; i < table.rows.length; i++) {
      table.rows[i].deleteCell(columnIndex);
    }
  };
  
  const addRow = () => {
    const table = tableDivRef.current.querySelector('table');
    const newRow = table.insertRow();
    const columnCount = table.rows[0].cells.length;
  
    for (let i = 0; i < columnCount; i++) {
      const newCell = newRow.insertCell(i);
      const referenceCell = table.rows[1].cells[i];
      newCell.innerHTML = `New Cell ${i + 1}`;
      newCell.style.cssText = referenceCell.style.cssText;
    }
  
    // Add the delete button for the new row
    const deleteCell = newRow.insertCell(-1);
    deleteCell.innerHTML = `<button>-</button>`;
    deleteCell.style.cssText = 'cursor: pointer; text-align: center;';
    deleteCell.addEventListener('click', () => handleDeleteRow(newRow.rowIndex));
  };
  
  const addColumn = () => {
    const table = tableDivRef.current.querySelector('table');
    const rowCount = table.rows.length;
  
    for (let i = 0; i < rowCount; i++) {
      const newCell = table.rows[i].insertCell(-1);
      const referenceCell = table.rows[i].cells[table.rows[i].cells.length - 2];
      if (i === 0) {
        newCell.innerHTML = `New Column`;
      } else {
        newCell.innerHTML = `New Cell ${i + 1}`;
      }
      newCell.style.cssText = referenceCell.style.cssText;
    }
  
    // Add the delete button for the new column
    const headerRow = table.rows[0];
    const deleteHeaderCell = headerRow.insertCell(-1);
    deleteHeaderCell.innerHTML = `<button>-</button>`;
    deleteHeaderCell.style.cssText = 'cursor: pointer; text-align: center;';
    deleteHeaderCell.addEventListener('click', () => handleDeleteColumn(headerRow.cells.length - 2));
  };
  
  const initializeTable = () => {
   if(newText){
    const table = tableDivRef.current.querySelector('table');
    alert("In Initialize")
    if (table) {
      const rows = table.rows;
      for (let i = 0; i < rows.length; i++) {
        const cells = rows[i].cells;
        for (let j = 0; j < cells.length; j++) {
          // Add click event listener to each existing cell if necessary
        }
  
        // Add delete button for existing rows
        const deleteCell = rows[i].insertCell(-1);
        deleteCell.innerHTML = `<button>-</button>`;
        deleteCell.style.cssText = 'cursor: pointer; text-align: center;';
        deleteCell.addEventListener('click', () => handleDeleteRow(i));
      }
  
      // Add delete buttons for existing columns
      const headerRow = table.rows[0];
      for (let i = 0; i < headerRow.cells.length; i++) {
        const deleteHeaderCell = headerRow.cells[i];
        deleteHeaderCell.innerHTML += ` <button>-</button>`;
        deleteHeaderCell.style.cssText = 'cursor: pointer; text-align: center;';
        deleteHeaderCell.addEventListener('click', () => handleDeleteColumn(i));
      }
    }
  }
  };
  const uploadNewImage = async () => {
    if (!newImage) return;
    console.log(tempDocument);
    const documentId = tempDocument.id? tempDocument.id : tempDocument._id;
    const formData = new FormData();
   const highlightId = highlight?.id?.id
    formData.append('image', selectedImage);
    formData.append('highlightId', highlightId);
    formData.append('documentId', documentId);
    try {
      const response = await uploadImgforDocument(documentId,highlightId,formData)
      console.log("response in docsHighlightModel",response );
      return response.data;
    } catch (error) {
      console.error('Error uploading image:', error);
    }
    
  };

  
  // Ensure the newText state updates when initialText changes
  useEffect(() => {
    // setInitialText(initialText);
    setHighlightType(highlight.type);
    setNewImage(null);
    /* if(highlight.type==='table'){ 
    const parser = new DOMParser();
    const doc = parser.parseFromString(initialText, 'text/html');
    const table = doc.querySelectorAll('table');
    console.log(table);
    if(table){
     // table[0]?.setAttribute('contenteditable', 'true');
    };
    setNewText(doc.body.innerHTML);
   } */
  }, [highlight,tempDocument]);

  const handleSaveImage = async() => {
   // console.log("in handle Save Image", initialText)
   if(!newImage) { 
      alert("please select an image to save.");
      return;
   }
   const newImageName= await uploadNewImage();
   console.log(newImageName);
   const newSrc=await getImgLink(newImageName);
    //const newUpdatedImage = document.getElementById('newImage').innerHTML;
    const parser = new DOMParser();
    const doc = parser.parseFromString(highlight.text, 'text/html');
    const img = doc.querySelectorAll('img');
     if(img){
      img[0].src = newSrc;
    };
    console.log(img[0]);  
    const edittedImage =img[0];
    onSave(edittedImage?.outerHTML);
    setNewImage(null);
    onClose();
  }

  const handleSaveTable = () => {
     const newUpdatedTable = document.getElementById('dynamicTable').innerHTML;
    const parser = new DOMParser();
    const doc = parser.parseFromString(newUpdatedTable, 'text/html');
    const table = doc.querySelectorAll('table');
     if(table){
      table[0].removeAttribute('contenteditable');
    //  const controls = table[0].querySelectorAll('td .cell-controls-horizontal, td .cell-controls-vertical');
    //  controls.forEach(control => control.remove());
    };
    console.log(table[0]);
    const edittedTable =table[0];
    onSave(edittedTable?.outerHTML);
    onClose();
  }
 
  
  const cancelEditImage =()=> {
    setNewImage(null);
    onClose();
  }

  const cancelTableEdit = () =>{
    setNewText(null);
    onClose();
  }

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50 overflow-auto">
    <div className="bg-white rounded-lg shadow-lg w-auto max-w-[90%] min-w-[700px] max-h-[80%] overflow-auto">
      <div className="bg-gray-300 p-2 rounded-t-lg">
        <h2 className="text-lg font-medium text-gray-800">{highlight.label}</h2>
      </div>
      <div className="p-4">
        {highlight.type === 'image' && (
          <>
            <div className="mt-4">
              <label htmlFor="originalText" className="block text-sm font-medium text-gray-700">
                Original Image:
              </label>
              <div
                id="originalText"
                dangerouslySetInnerHTML={{ __html: highlight.text }}
                className="mt-2 w-64 h-64 object-cover"
              />
            </div>
            <div className="image-uploader">
              {newImage && (
                <>
                  <label className="block text-sm font-medium text-gray-700">New Image:</label>
                  <div id="newImage">
                    <img src={newImage} alt="Uploaded" className="mt-2 w-64 h-64 object-cover" />
                  </div>
                </>
              )}
            </div>
            <div className="block text-sm font-medium text-gray-700 mt-4 flex justify-end space-x-2">
            <div className='left-buttons mb-2'>
            <input
              type="file"
              name="selectedImage"
              accept="image/png,image/jpeg"
              onChange={handleImageUpload}
              className="mt-2"
              ref={inputRef}
              hidden
            />
            <button
              type="button"
              className="block text-gray-900 border border-gray-300 rounded-lg cursor-pointer
          bg-gray-50 dark:text-gray-400 hover:bg-gray-100 dark:bg-gray-700 dark:border-gray-600
          dark:hover:bg-gray-600 focus:outline-none py-2 px-4"
              onClick={() => inputRef.current.click()}
            >
              Change Image
            </button>
            </div>
            <div className='right-buttons  text-right'>
         
            <button
              className="inline-flex justify-center px-4 py-2 text-sm font-medium mr-2 text-gray-700 bg-gray-200 border border-transparent rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              onClick={cancelEditImage}
            >
              Cancel
            </button>
            <button
              className={`inline-flex justify-center px-4 py-2 text-sm font-medium border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 
                 ${ newImage
                   ? 'text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                   : 'text-gray-500 bg-gray-300 cursor-not-allowed focus:ring-gray-400'
                 }`}
              onClick={handleSaveImage}
              disabled={!newImage}
            >
              Save
            </button>
            </div>
          </div>
          </>
        )}
        {highlight.type === 'table' && (
          <>
           
            <div className="mt-4 hidden">
              <label htmlFor="originalText" className="block text-sm font-medium text-gray-700">
                Original Table:
              </label>
              <div id="originalText" dangerouslySetInnerHTML={{ __html: highlight.text }} />
           
             <div id="newTable" ref={tableDivRef} dangerouslySetInnerHTML={{ __html: newText }} />
            </div>
             <div className="block text-sm font-medium text-gray-700 mt-2">
             <div className="flex space-x-2">
              <DynamicTable  initialTableHtml={highlight.text} setNewText={setNewText} handleSaveTable={handleSaveTable}/>
              </div>
              <div className="flex justify-between">
            
                <div className="flex space-x-2">
                  <button
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 border border-transparent rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    onClick={cancelTableEdit}
                  >
                    Cancel
                  </button>
                  <button
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={handleSaveTable}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div> 
          
          </>
        )}
      </div>
    </div>
  </div>
  
  );
};

export default DocumentHighlightsModal;
